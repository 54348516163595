@import "variables";
@import "mixins";
@import "reset";

/*******************/

/*** Components ***/

/*****************/

/*General*/

@import "components/general/typorgraphy";
@import "components/general/buttons";
@import "components/general/icons";

/*Layout*/

@import "components/layout/breakpoint";
@import "components/layout/grid";

/*Navigation*/

@import "components/navigation/_affix";
@import "components/navigation/_breadcrumb";
@import "components/navigation/dropdown";
@import "components/navigation/menu";
@import "components/navigation/pagination";
@import "components/navigation/page-header";
@import "components/navigation/steps";

/*Data Entry*/

@import "components/data-entry/autocomplete";
@import "components/data-entry/cascader";
@import "components/data-entry/checkbox";
@import "components/data-entry/date-picker";
@import "components/data-entry/form";
@import "components/data-entry/input";
@import "components/data-entry/mention";
@import "components/data-entry/radio";
@import "components/data-entry/rate";
@import "components/data-entry/select";
@import "components/data-entry/slider";
@import "components/data-entry/switch";
@import "components/data-entry/time-picker";
@import "components/data-entry/transfer";
@import "components/data-entry/tree-select";
@import "components/data-entry/upload";

/*Data Display*/

@import "components/data-display/avatar";
@import "components/data-display/badge";
@import "components/data-display/calendar";
@import "components/data-display/card";
@import "components/data-display/carousel";
@import "components/data-display/collapse";
@import "components/data-display/comment";
@import "components/data-display/descriptions";
@import "components/data-display/list";
@import "components/data-display/popover";
@import "components/data-display/statistic";
@import "components/data-display/table";
@import "components/data-display/tabs";
@import "components/data-display/tag";
@import "components/data-display/timeline";
@import "components/data-display/tooltip";
@import "components/data-display/tree";

/*Feedback*/

@import "components/feedback/alert";
@import "components/feedback/drawer";
@import "components/feedback/message";
@import "components/feedback/modal";
@import "components/feedback/notifications";
@import "components/feedback/progress";
@import "components/feedback/spin";

/*Other*/

@import "components/others/anchor";
@import "components/others/back-to-top";
@import "components/others/divider";

/******************/

/*** Template ****/

/****************/

@import "template/header.scss";
@import "template/layout.scss";
@import "template/side-nav.scss";
@import "template/footer.scss";

/*****************/

/***** Apps *****/

/***************/

@import "apps/chat.scss";
@import "apps/file-manager.scss";
@import "apps/mail.scss";
@import "apps/e-commerce.scss";

/*****************/

/*** Plugins ****/

/***************/

@import "plugins/chartist.scss";
@import "plugins/quill.scss";

/*******************/

/*** Utilities ****/

/*****************/

@import "utilities/utilities-bs.scss";
@import "utilities/utilities-enlink.scss";

/*******************/

/****** Demo ******/

/*****************/

@import "demo/demo";
@for $i from 1 through 100 {
  .f-#{$i} {
    font-size: $i * 1px !important;
  }
}

.link {
  color: #1890ff;
}

.cursor-pointer {
  cursor: pointer;
  &:hover {
    color: #0a3780;
  }
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.ant-checkbox-wrapper {
  align-items: center !important;
}

.ant-radio-inner:after {
  height: 16px !important;
  width: 16px !important;
  left: 50% !important;
  top: 50% !important;
}

.open-table-extend-footer .ant-table-pagination.ant-pagination {
  position: relative;
  top: -74px;
  align-items: center;
  float: right;
}

.ant-input:disabled,
.ant-radio-disabled + span,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker-input > input[disabled] {
  color: black;
}

.ant-radio-disabled .ant-radio-inner:after {
  background-color: black;
}

.refuse-button-background {
  background-color: #fff;
  color: #dc1e2a;
  border-color: #dc1e2a;
  &:hover {
    background-color: #dc1e2a !important;
    color: #fff;
    border-color: #dc1e2a !important;
  }
}

.primary-button,
.secondary-button {
  &:hover {
    background-image: linear-gradient(to right, #5198ea 0%, #298cff 100%);
    border-color: #298cff !important;
    color: #fff;
  }
}

.ant-btn[disabled]:hover {
  border-color: #edf2f9 !important;
}

b, p {
  color: #333;
}

.ant-radio-inner {
  border-color: #000;
}


.ant-form-item-label>label::after {
  display: none;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: black;
  border-color: black;
}

.border {
  border: 1px solid #c0c0c0 !important;
}

p {
  color: #333
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    -4.091953446688478deg,
    #2489ff 0%,
    rgba(36, 137, 255, 0.98) 8.516889956203489%,
    rgba(36, 137, 255, 0.98) 8.516889956203489%,
    rgba(140, 193, 255, 0.99) 100%
  );

  border-radius: 10px !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.ant-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  transition: all 0.1s !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  &:hover {
    color: #000;
    font-weight: 500;
  }
}

.ant-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 2px solid #000;
}

.tab-container {
  padding: 0 1.25rem;
}

.gold-button {
  background-color: #8d800a;
  border-color: #8d800a;
}

.table-icons {
  cursor: pointer;
  font-size: 24px;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-footer {
  border-top: none;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: bold;
}

.information-container {
  border: 1px solid #2489ff;
  background-color: #91caff;
}

.info-icon {
  color: #2489ff;
}

.back-icon {
  cursor: pointer;
  color: #000;
}

.ant-badge-count {
  color: #fff;
  background-color: #000;
}

.autocomplete-notification-container {
  border: 1px solid #91caff;
  background-color: #e6f4ff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.gold-button {
  background-color: #8d800a;
  border-color: #8d800a;
}